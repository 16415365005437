import { useEffect, useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LanguageSwitch,
  LanguageSwitchContainer,
  LanguageSwitchContainerInlineRow,
} from "./styles";
import { PngImg } from "../../common/PngImg";
import i18n from "i18next";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [showLangues, setShowLanguages] = useState(false);
  const [langues, setLanguages] = useState([
    ["pt", "brazil.svg"],
    ["en", "united-states.svg"],
    ["es", "spain.svg"],
  ]);
  const [currentLanguage, setCurrentLanguage] = useState(
    langues.filter((e) => {
      return e[0] === language;
    })
  );
  const [notSelectedLanguages, setNotSelectedLanguages] = useState(
    langues.filter((e) => {
      return e[0] !== language;
    })
  );

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(
      langues.filter((e) => {
        return e[0] === language;
      })
    );
    setNotSelectedLanguages(
      langues.filter((e) => {
        return e[0] !== language;
      })
    );
  };

  const handleVisibity = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("intro")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("stacks")}>
          <Span>Stacks</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("projects")}>
          <Span>{t("Projects")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          onMouseEnter={() => setShowLanguages(true)}
          onMouseLeave={() => setShowLanguages(false)}
          style={{
            width: "30px",
            margin: 0,
          }}
        >
          <LanguageSwitchContainer>
            <LanguageSwitch
              onClick={() => handleChange(`${currentLanguage[0][0]}`)}
            >
              <SvgIcon
                src={`${currentLanguage[0][1]}`}
                aria-label="homepage"
                width="30px"
                height="30px"
              />
            </LanguageSwitch>

            {showLangues
              ? notSelectedLanguages.map((element, i) => {
                  return (
                    <LanguageSwitch
                      onClick={() => handleChange(`${element[0]}`)}
                    >
                      <SvgIcon
                        src={`${element[1]}`}
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                      />
                    </LanguageSwitch>
                  );
                })
              : null}
          </LanguageSwitchContainer>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngImg src="logo.png" className="img"/>
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={handleVisibity}>
            <CustomNavLinkSmall>
              <LanguageSwitchContainerInlineRow>
                <LanguageSwitch onClick={() => handleChange("pt")}>
                  <SvgIcon
                    src="brazil.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainerInlineRow>
            </CustomNavLinkSmall>
          </Burger>
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
