import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;

  .ant-row-space-between {
    align-items: flex-start;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  margin-top: 1rem;
  padding-bottom: 5rem;
  
  .img{
    width: 210px;
  }

  @media only screen and (max-width: 1013px) {
    .img{
      max-width: 120px;
    }
}
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div") <any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 1013px) {
    display: block;
  }

  display: none;

  /* svg {
    fill: white;
  } */
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 1013px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #FFFFFF;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined) <any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #B2FCFB;
    text-underline-position: under;
    text-decoration: #B2FCFB underline;
  }
`;

export const LogoImg = styled("img")`
  
`
export const LanguageSwitch = styled("div") <any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  margin-bottom: 10px;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LanguageSwitchContainerInlineRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 140%;
`;